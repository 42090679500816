export const tweets = [
    'https://x.com/RanggaPurAji/status/1762319154865115355?s=20',
    'https://twitter.com/PawelDudko/status/1659283408097271810?s=20',
    'https://twitter.com/FluffheadChaser/status/1655568735611265024?s=20',
    'https://twitter.com/VectorZ3R0/status/1654133263965859840?s=20',
    'https://twitter.com/agrshch/status/1654106267667898368?s=20',
    'https://twitter.com/FluffheadChaser/status/1653474796984778755?s=20',
    'https://twitter.com/UnrulyUnroll/status/1653477732943122432?s=20',
    'https://twitter.com/shaderism/status/1649855175765508108?s=20',
    'https://twitter.com/vedrrran/status/1645725818813906944?s=20',
    //'https://twitter.com/mariuswatz/status/1643270196231020546?s=20',
    'https://twitter.com/defibeast/status/1644266866783952897?s=20',
    'https://twitter.com/pabloAlpe_/status/1640018195217039361?s=20',
    'https://twitter.com/autoeclectus/status/1639966467692318721?s=20',
    //'https://twitter.com/jagracar/status/1639921583769657345?s=20',
    //'https://twitter.com/akashitez/status/1639919007217356801?s=20',
    //'https://twitter.com/loackme_/status/1639920256377315330?s=20',
    'https://twitter.com/mbransn/status/1639915556781576192?s=20',
    'https://twitter.com/cccooco_/status/1639912397577093120?s=20',
    'https://twitter.com/loackme_/status/1639911886299754498?s=20',
    'https://twitter.com/patricia_klein_/status/1639905193968934912?s=20',
    'https://twitter.com/mbransn/status/1639909381004496896?s=20',
    'https://twitter.com/loackme_/status/1639678276598726657?s=20',
   // 'https://twitter.com/AntonioWerli/status/1639674533656313857?s=20',
    'https://twitter.com/NorthKoreanDeFi/status/1639652543511896065?s=20',
    'https://twitter.com/captainjacknft/status/1639661842548113409?s=20',
    'https://twitter.com/GenticGoth/status/1628920694733180928?s=20',
    'https://twitter.com/woutmissler/status/1639640616123473920?s=20',
    'https://twitter.com/loackme_/status/1639643242604949504?s=20',
    'https://twitter.com/_____gems_____/status/1639643585044791302?s=20',
]